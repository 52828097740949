<template>
  <v-card class="pa-5 h-100">
    <v-container>
      <v-row>
        <v-col cols="10"><div class="card-title">Datos Empresa</div></v-col>
        <!-- <v-col cols="2">
          <button @click="handleClick(0)">
            <v-img
              max-height="32"
              max-width="32"
              :src="require('@/assets/img/profile/edit.png')"
            ></v-img>
          </button>
        </v-col> -->
      </v-row>
    </v-container>
    <v-card-text>
      <table>
        <tr>
          <td class="left">Nombre Empresa</td>
          <td v-if="company.name" class="right">{{ company.name }}</td>
          <td v-else class="right" style="color: #d50000">Información no registrada</td>
        </tr>

        <tr>
          <td class="left">Rol Único Tributario</td>
          <td v-if="company.tributaryId" class="right">
            {{ company.tributaryId }}
          </td>
          <td v-else class="right" style="color: #d50000">Información no registrada</td>
        </tr>
        <tr>
          <td class="left">Tamaño Empresa</td>
          <td v-if="sizeFullData" class="right">{{ sizeFullData }}</td>
          <td v-else class="right" style="color: #d50000">Información no registrada</td>
        </tr>
        <tr>
          <td class="left">Inicio Actividades</td>
          <td v-if="company.trajectory" class="right">
            {{ company.trajectory }}
          </td>
          <td v-else class="right" style="color: #d50000">Información no registrada</td>
        </tr>
        <tr>
          <td class="left">País</td>
          <td v-if="countryValue.name" class="right">
            {{ countryValue.name }}
          </td>
          <td v-else class="right" style="color: #d50000">Información no registrada</td>
        </tr>
        <tr>
          <td class="left">Región</td>
          <td v-if="regionValue" class="right">{{ regionValue }}</td>
          <td v-else class="right" style="color: #d50000">Información no registrada</td>
        </tr>
        <tr>
          <td class="left">Negocio Principal</td>
          <td v-if="industrialsectorValue" class="right">
            {{ industrialsectorValue }}
          </td>
          <td v-else class="right" style="color: #d50000">Información no registrada</td>
        </tr>
        <tr>
          <td class="left">Sitio Web</td>
          <td v-if="company.web" class="right">{{ company.web }}</td>
          <td v-else class="right" style="color: #d50000">Información no registrada</td>
        </tr>
        <tr>
          <td class="left">LinkedIn</td>
          <td v-if="company.linkedIn" class="right">{{ company.linkedIn }}</td>
          <td v-else class="right" style="color: #d50000">Información no registrada</td>
        </tr>
        <tr>
          <td class="left">Acerca de la Empresa</td>
          <td v-if="company.contributionValue" class="right">
            {{ company.contributionValue }}
          </td>
          <td v-else class="right" style="color: #d50000">Información no registrada</td>
        </tr>
      </table>
      <v-dialog v-model="modal" max-width="600" v-if="modalState === 0">
        <v-card>
          <v-container class="pa-10">
            <div class="title">Editar Datos Empresa</div>
            <v-form class="mt-8">
              <v-row>
                <v-col cols="6">
                  <label>Nombre Empresa</label>
                  <v-text-field
                    v-model="company.name"
                    disabled
                    no-resize
                    outlined
                    color="#7319D5"
                    placeholder="Describe brevemente sobre tu producto o servicio"
                    hide-details
                    class="roboto"
                  />
                </v-col>
                <v-col cols="6">
                  <label>Rol Único Tributario</label>
                  <v-text-field
                    v-model="company.tributaryId"
                    no-resize
                    disabled
                    outlined
                    color="#7319D5"
                    placeholder="Describe brevemente sobre tu producto o servicio"
                    hide-details
                    class="roboto"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <label>Tamaño Empresa</label>
                  <v-text-field
                    v-model="sizeFullData"
                    no-resize
                    disabled
                    outlined
                    color="#7319D5"
                    placeholder="Describe brevemente sobre tu producto o servicio"
                    hide-details
                    class="roboto"
                  />
                </v-col>
                <v-col cols="6">
                  <label>Inicio Actividades</label>
                  <v-text-field
                    v-model="company.trajectory"
                    no-resize
                    disabled
                    outlined
                    color="#7319D5"
                    placeholder="Describe brevemente sobre tu producto o servicio"
                    hide-details
                    class="roboto"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <label>País</label>
                  <v-text-field
                    v-model="countryValue.name"
                    no-resize
                    disabled
                    outlined
                    color="#7319D5"
                    placeholder="Describe brevemente sobre tu producto o servicio"
                    hide-details
                    class="roboto"
                  />
                </v-col>
                <v-col cols="6">
                  <label>Región</label>
                  <v-text-field
                    v-model="regionFormValue"
                    no-resize
                    disabled
                    outlined
                    color="#7319D5"
                    placeholder=""
                    hide-details
                    class="roboto"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <label>Negocio Principal</label>
                  <v-text-field
                    v-model="industrialsectorValue"
                    no-resize
                    outlined
                    disabled
                    color="#7319D5"
                    placeholder="Describe brevemente sobre tu producto o servicio"
                    hide-details
                    class="roboto"
                  />
                </v-col>
                <v-col cols="12">
                  <label>Sitio Web</label>
                  <v-text-field
                    v-model="web"
                    no-resize
                    outlined
                    color="#7319D5"
                    placeholder="Describe brevemente sobre tu producto o servicio"
                    hide-details
                    class="roboto"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <label>LinkedIn</label>
                  <v-text-field
                    v-model="linkedin"
                    no-resize
                    outlined
                    color="#7319D5"
                    placeholder="Describe brevemente sobre tu producto o servicio"
                    hide-details
                    class="roboto"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <label>Acerca de la Empresa</label>
                  <v-textarea
                    v-model="about"
                    no-resize
                    outlined
                    color="#7319D5"
                    placeholder="Describe brevemente sobre tu producto o servicio"
                    hide-details
                    class="roboto"
                  />
                </v-col>
              </v-row>
            </v-form>

            <v-row justify="center" class="roboto mt-8">
              <v-col class="pa-0 ma-0" order="2" order-md="1" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                <v-btn text rounded width="100%" color="tway-violet" @click="goBack()"> CANCELAR </v-btn>
              </v-col>
              <v-col
                class="pa-0 ma-0 ml-md-4 mb-4"
                order="1"
                order-md="2"
                cols="12"
                xs="12"
                sm="12"
                md="5"
                lg="5"
                xl="5"
              >
                <v-btn rounded width="100%" color="tway-violet" class="white--text" @click="editData()">
                  GUARDAR CAMBIOS
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="modal" max-width="600" v-if="modalState === 1">
        <v-card>
          <v-container>
            <v-row align="center" justify="center" class="mt-6">
              <v-img :src="require('@/assets/img/mdi-check-circle.png')" max-width="73px" max-height="73px" />
            </v-row>
            <v-container style="max-width: 500px; text-align: center">
              <v-card-text
                class="mt-5"
                style="
                  font-family: Josefin Sans;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 25px;
                  line-height: 30px;
                  color: #333333;
                "
              >
                Datos actualizados exitosamente
              </v-card-text>
            </v-container>
            <v-row justify="center" class="mb-5 mt-2">
              <v-btn outlined class="button-text tway-violet white--text" rounded @click="goBack()"> aceptar </v-btn>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import industrialSectorJSON from "@/utils/industrialSector.json";
import sizeJSON from "@/utils/size.json";
import servicesJSON from "@/utils/services.json";
import { mapActions } from "vuex";
export default {
  props: {
    company: {
      type: Object,
      default: () => {},
    },
    countryValue: {
      type: Object,
      default: () => {},
    },
    regionValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      modal: false,
      modalState: 0,
      sizeFullData: "",
      industrialsectorValue: "",
      availableSizes: sizeJSON,
      availableIndustrialSectors: industrialSectorJSON,
      availableServices: servicesJSON,
      about: "",
      linkedin: "",
      web: "",
      regionFormValue: "",
    };
  },
  async created() {
    this.fillIndustrialSector();
    this.fillSizeFullData();
  },
  methods: {
    ...mapActions("CompanyStore", ["editCompany"]),
    handleClick(key) {
      this.modal = true;
      this.modalState = key;
    },
    goBack() {
      this.modal = false;
      this.modalState = 0;
    },
    async editData() {
      let data = {
        contributionValue: this.about,
        linkedin: this.linkedin,
        web: this.web,
      };
      await this.editCompany(data).then(() => {
        this.modalState == 1;
      });
    },
    fillIndustrialSector() {
      if (this.company.type === "CLIENT") {
        this.industrialsectorValue = this.company.industrialSector.name;
      }
      if (this.company.type === "SPECIALIST") {
        let values = [];
        this.company.industrialSectors.forEach((element) => {
          values.push(element.name);
        });
        this.industrialsectorValue = values;
      }
    },
    fillSizeFullData() {
      if (this.company.size !== null) {
        this.sizeFullData = this.company.size.name + " " + this.company.size.salesUfRange;
      }
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style>
th,
td {
  padding: 10px;
}
.left {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #333333;
  margin-right: 100px;
}
.right {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */

  /* Gris Oscuro / Gris Oscuro 3 */

  color: #999999;
}
.title {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  /* identical to box height, or 33px */

  /* Tway / Gris Oscuro */

  color: #333333;
}
</style>
