<template>
  <v-card class="pa-5 h-100">
    <v-container>
      <v-row>
        <v-col cols="10"><div class="card-title">Datos Personales</div></v-col>
        <!-- <v-col cols="2">
          <button @click="handleClick(2)">
            <v-img
              max-height="32"
              max-width="32"
              :src="require('@/assets/img/profile/edit.png')"
            ></v-img>
          </button>
        </v-col> -->
      </v-row>
    </v-container>
    <v-card-text>
      <table>
        <tr>
          <td class="left">Nombre</td>
          <td v-if="user.firstName" class="right">{{ user.firstName }}</td>
          <td v-else class="right" style="color: #d50000">Información no registrada</td>
        </tr>

        <tr>
          <td class="left">Apellido</td>
          <td v-if="user.lastName" class="right">{{ user.lastName }}</td>
          <td v-else class="right" style="color: #d50000">Información no registrada</td>
        </tr>
        <tr>
          <td class="left">Área de trabajo</td>
          <td v-if="businessAreaValue" class="right">
            {{ businessAreaValue }}
          </td>
          <td v-else class="right" style="color: #d50000">Información no registrada</td>
        </tr>
        <tr>
          <td class="left">Correo</td>
          <td v-if="user.username" class="right">{{ user.username }}</td>
          <td v-else class="right" style="color: #d50000">Información no registrada</td>
        </tr>
      </table>
      <v-row class="mt-10">
        <v-col cols="12">
          <label class="left">Contraseña</label>
        </v-col>
        <v-col cols="12">
          <v-row align="center">
            <v-col :cols="desktop ? '6' : '12'">
              <v-text-field
                type="password"
                disabled
                v-model="passwordFake"
                no-resize
                outlined
                color="#7319D5"
                placeholder="Describe brevemente sobre tu producto o servicio"
                hide-details
                class="roboto"
              />
            </v-col>
            <v-col :cols="desktop ? '6' : '12'">
              <v-btn
                style="height: 36px; font-weight: bold; font-size: 14px; line-height: 16px; letter-spacing: 0.1em"
                outlined
                color="deep-purple accent-4"
                class="roboto"
                rounded
                @click="handleClick(0)"
              >
                MODIFICAR CONTRASEÑA
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="modal" max-width="600" v-if="modalState === 0">
        <v-card class="pa-7">
          <div class="text-title mb-6">Modificar Contraseña</div>

          <v-form v-model="valid" ref="form">
            <v-row>
              <v-col>
                <v-text-field
                  label="Ingresa tu contraseña actual"
                  name="oldPassword"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="oldPassword"
                  :rules="[required('Contraseña actual')]"
                  required
                  outlined
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Ingresa tu contraseña nueva"
                  name="password"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password"
                  :rules="[required('Contraseña nueva'), validatePassword()]"
                  required
                  outlined
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Vuelve a ingresar tu nueva contraseña"
                  name="confirmPassword"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="passwordConfirm"
                  :rules="[required('Confirmación de contraseña'), validatePassword()]"
                  :error-messages="passwordMatchError()"
                  required
                  outlined
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-container
                ><div class="mr-3">La nueva contraseña debe incluir:</div>
                <div class="text-list pa-0 mb-6 ma-0">
                  <v-col>
                    <ul>
                      <li>Un mínimo de nueve caracteres</li>
                      <li>Al menos un número</li>
                      <li>Al menos una letra mayúscula</li>
                      <li>Al menos una letra minúscula</li>
                      <li>Al menos un carácter especial (!, @, #, $, %, ^, &, *)</li>
                    </ul>
                  </v-col>
                </div></v-container
              >
            </v-row>
            <v-row justify="center" class="roboto">
              <v-col class="pa-0 ma-0" order="2" order-md="1" cols="12" md="3">
                <v-btn text rounded width="100%" color="tway-violet" @click="goBack()"> CANCELAR </v-btn>
              </v-col>
              <v-col class="pa-0 ma-0 ml-md-4 mb-4" order="1" order-md="2" cols="12" md="5">
                <v-btn rounded width="100%" color="tway-violet" class="white--text" @click="changePassword()">
                  GUARDAR CAMBIOS
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog v-model="modal" max-width="600" v-if="modalState === 1">
        <v-card>
          <v-container>
            <v-row align="center" justify="center" class="mt-6">
              <v-img :src="require('@/assets/img/mdi-check-circle.png')" max-width="73px" max-height="73px" />
            </v-row>
            <v-container style="max-width: 500px; text-align: center">
              <v-card-text
                class="mt-5"
                style="
                  font-family: Josefin Sans;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 25px;
                  line-height: 30px;
                  color: #333333;
                "
              >
                La contraseña se ha guardado exitosamente
              </v-card-text>
            </v-container>
            <v-row justify="center" class="mb-5 mt-2">
              <v-btn outlined class="button-text tway-violet white--text" rounded @click="goBack()"> aceptar </v-btn>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="modal" max-width="600" v-if="modalState === 2"> </v-dialog>
      <!-- <v-form>
        <v-row>
          <v-col cols="4">
            <label>Nombre</label>
            <v-text-field
              v-model="user.firstName"
              :disabled="!editMode"
              no-resize
              outlined
              color="#7319D5"
              placeholder="Describe brevemente sobre tu producto o servicio"
              hide-details
              class="roboto"
            />
          </v-col>
          <v-col cols="4">
            <label>Apellido</label>
            <v-text-field
              v-model="user.lastName"
              :disabled="!editMode"
              no-resize
              outlined
              color="#7319D5"
              placeholder="Describe brevemente sobre tu producto o servicio"
              hide-details
              class="roboto"
            />
          </v-col>
          <v-col cols="4">
            <label>Edad</label>
            <v-text-field
              v-model="ageValue"
              :disabled="!editMode"
              no-resize
              outlined
              color="#7319D5"
              placeholder="Describe brevemente sobre tu producto o servicio"
              hide-details
              class="roboto"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <label>Área de trabajo</label>
            <v-text-field
              v-model="businessAreaValue"
              :disabled="!editMode"
              no-resize
              outlined
              color="#7319D5"
              placeholder="Describe brevemente sobre tu producto o servicio"
              hide-details
              class="roboto"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <label>Correo</label>
            <v-text-field
              v-model="user.username"
              disabled
              no-resize
              outlined
              color="#7319D5"
              placeholder="Describe brevemente sobre tu producto o servicio"
              hide-details
              class="roboto"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <label>Contraseña</label>
            <v-text-field
              type="password"
              disabled
              v-model="password"
              no-resize
              outlined
              color="#7319D5"
              placeholder="Describe brevemente sobre tu producto o servicio"
              hide-details
              class="roboto"
            />
          </v-col>
          <v-col cols="4" align-self="center" class="mt-6">
            <v-btn
              style="
                height: 36px;
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.1em;
              "
              outlined
              color="deep-purple accent-4"
              class="roboto"
              rounded
              :to="{ name: 'change-password' }"
            >
              MODIFICAR CONTRASEÑA
            </v-btn>
          </v-col>
        </v-row>
      </v-form> -->
    </v-card-text>
  </v-card>
</template>

<script>
import currentUserService from "@/services/currentUserService";
import ValidatorsService from "@/services/validatorsService";

export default {
  props: {
    businessAreaValue: {
      type: String,
      default: "",
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      modal: false,
      modalState: 0,
      editMode: false,
      passwordFake: "unacontraseña",
      valid: true,
      oldPassword: "",
      password: "",
      passwordConfirm: "",
      showPassword: false,
      passwordChanged: false,
    };
  },
  methods: {
    required: (propertyType) => ValidatorsService.required(propertyType),

    validatePassword: () => ValidatorsService.validatePassword(),

    passwordMatchError: function () {
      return this.password === this.passwordConfirm ? "" : "Contraseñas deben ser iguales";
    },

    changePassword() {
      if (this.$refs.form.validate()) {
        let passwords = {
          oldPassword: this.oldPassword,
          password: this.password,
          passwordConfirm: this.passwordConfirm,
        };

        currentUserService
          .changePassword(passwords)
          .then(() => {
            this.modalState = 1;
            this.password = "";
            this.oldPassword = "";
            this.passwordConfirm = "";
            this.passwordChanged = false;
          })
          .catch((err) => {
            this.$log.error(err);
            this.password = "";
            this.oldPassword = "";
            this.passwordConfirm = "";
            this.passwordChanged = false;
          });
      }
    },
    goBack() {
      this.modal = false;
      this.modalState = 0;
    },
    handleClick(key) {
      this.modal = true;
      this.modalState = key;
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style>
th,
td {
  padding: 10px;
}
.left {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #333333;
  margin-right: 100px;
}
.right {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */

  /* Gris Oscuro / Gris Oscuro 3 */

  color: #999999;
}
</style>
