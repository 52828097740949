<template>
  <v-card class="py-3 px-3 h-100">
    <v-row align="center">
      <v-col cols="5">
        <v-img
          v-if="companyType == 'SPECIALIST'"
          :src="require('@/assets/img/perfilamiento-e.svg')"
          class="rounded"
          max-width="100%"
          contain
        />
        <v-img
          v-if="companyType == 'CLIENT'"
          :src="require('@/assets/img/perfilamiento.svg')"
          class="rounded"
          max-width="100%"
          contain
        />
      </v-col>
      <v-col cols="7">
        <div>Perfil</div>
        <strong v-if="companyType == 'SPECIALIST'" class="text-h6 text--tway-apple-green">Especialista</strong>
        <strong v-if="companyType == 'CLIENT'" class="text-h5 text--tway-apple-green">Cliente</strong>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels flat>
          <v-expansion-panel @click="handleClick()">
            <v-expansion-panel-header>
              <span style="color: #7319d5" v-if="!expanded"> Ver más</span>
              <span style="color: #7319d5" v-if="expanded"> Ver menos</span>
              <template v-slot:actions>
                <v-icon color="#7319d5"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-col>
                <div class="subtitle-1">¿Qué puede hacer mi empresa en Tway?</div>
                <v-row
                  class="ma-0"
                  v-for="(link, index) in companyType === 'SPECIALIST' ? links.specialist : links.client"
                  :key="index"
                >
                  <v-col class="ma-0">
                    <span class="text-subtitle-2 roboto">{{ link }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    companyType: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      expanded: false,
      links: {
        client: [
          "• Conocer el Índice de Madurez en Transformación Digital",
          "• Acceder a Especialistas, rankeados y validados que ofrecen soluciones a la medida para mejorar la competitividad de tu negocio.",
          "• Medir el impacto de la Transformación Digital en tus indicadores de negocio.",
          "• Contar con acompañamiento del primer PMO Digital en Transformación Estratégica y mucho más.",
        ],
        specialist: [
          "• Promocionar productos y servicios.",
          "• Conectar con socios estratégicos.",
          "• Concretar nuevos negocios en el Marketplace.",
          "• Acceder a licitaciones.",
          "• Optimizar procesos de ventas.",
        ],
      },
    };
  },
  methods: {
    handleClick() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style></style>
