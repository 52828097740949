<template>
  <div>
    <breadcrumbs :items="breadcrumbs" />
    <Loader :visible="isLoading" />

    <div class="page-title">Mi Perfil</div>

    <v-container class="mb-4 mt-4 pa-0" fluid v-if="!isLoading">
      <v-row>
        <v-col :cols="desktop ? '9' : '12'">
          <v-row align="stretch">
            <v-col>
              <personal-data :user="user" :business-area-value="businessAreaValue" />
            </v-col>
          </v-row>
          <v-row align="stretch">
            <v-col>
              <company-data :company="company" :country-value="countryValue" :region-value="regionValue" />
            </v-col>
          </v-row>
        </v-col>
        <v-col :cols="desktop ? '3' : '0'" class="desktop">
          <what-can-i-do :company-type="company.type" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style lang="scss" scoped>
.page-title {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 150%;
  color: #333333;
}
.card-title {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  color: #333333;
}
label {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #333333;
}
</style>
<script>
import Loader from "@/components/Loader";
import breadcrumbs from "@/components/Breadcrumbs";
import companyService from "@/services/companyService";
import PersonalData from "@/components/profile/personalData";
import CompanyData from "@/components/profile/companyData";
import WhatCanIDo from "@/components/profile/whatCanIDo";
import currentUserService from "@/services/currentUserService";
import CountryCodes from "@/utils/CountryCodes.json";

export default {
  name: "MyProfile",

  components: {
    Loader,
    breadcrumbs,
    PersonalData,
    CompanyData,
    WhatCanIDo,
  },

  data: () => ({
    breadcrumbs: [
      {
        text: "Inicio",
        disabled: false,
        exact: true,
        to: {
          name: "home",
        },
      },
      {
        text: "Mi Perfil",
        disabled: true,
        exact: true,
        to: {
          name: "my-profile",
        },
      },
    ],
    isLoading: true,
    countryValue: { name: "", alpha: "", countryCode: "" },
    availableCountries: CountryCodes,
    availableRegions: [],
    availableBusinessArea: [],
    availableWorkplaceSeniority: [],
    regionValue: "",
    company: {},
    user: {},
    businessAreaValue: "",
  }),
  async created() {
    await this.getProfile().then(() => {
      this.fillCountryValue();
    });
    Promise.all([this.fillRegionAutocomplete(this.countryValue.countryCode), this.fillWorkAreaAutocomplete()])
      .then(() => {
        this.fillRegionData();
        this.getBusinessAreaValue();
      })
      .then(() => (this.isLoading = false));
  },
  methods: {
    async getProfile() {
      await companyService.getCompanyProfile().then((company) => (this.company = { ...company }));
      await currentUserService.currentUserData().then((userData) => (this.user = { ...userData }));
    },
    async fillWorkAreaAutocomplete() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("registration");
      const data = await companyService.getCompanyBusinessArea(token);
      this.availableBusinessArea = data;
    },
    getBusinessAreaValue() {
      if (this.user.metadata.businessArea !== "") {
        let data = this.availableBusinessArea.find((element) => element.code == this.user.metadata.businessArea);
        this.businessAreaValue = data != undefined ? data.name : "";
      }
    },
    async fillRegionAutocomplete(countryId) {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("registration");
      const data = await companyService.getCompanyRegion(countryId, token);
      this.availableRegions = data;
    },
    fillCountryValue() {
      let data = this.availableCountries.find(
        (element) => element.countryCode || element.alpha2 == this.company.countryCode
      );
      this.countryValue = data;
    },

    fillRegionData() {
      if (this.availableRegions !== null) {
        let data = this.availableRegions.find((element) => element.code === this.user.metadata.regionCode);
        this.regionValue = data != undefined ? data.name : "";
      }
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>
