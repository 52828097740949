<template>
  <div class="">
    <myProfile />
  </div>
</template>
<style lang="scss" scoped></style>
<script>
import myProfile from "@/components/profile/myProfile";

export default {
  name: "Profile",

  components: {
    myProfile,
  },
};
</script>
